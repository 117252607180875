<template>
	<div class="connent_title">
		<div class="connent_header">
			<span><a href="/ECharts/Demo/List">图表展示</a></span>
		</div>
		<el-link type="danger" target="_blank" :underline="false" href="https://echarts.apache.org/examples/zh/index.html">更多图表</el-link>
	</div>
	<br>
	<div style="width:100%;height:90%">
		<v-chart  :option="option"/>
	</div>
</template>

<script>

import echarts from "echarts";
import ECharts from "vue-echarts";
export default {
	components: {
		'v-chart': ECharts,
	},

	setup () {

		let option =  {
			textStyle: {
				fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
			},
			title: {
				text: "饼图",
						left: "center"
			},
			tooltip: {
				trigger: "item",
						formatter: "{a} <br/>{b} : {c} ({d}%)"
			},
			legend: {
				orient: "vertical",
						left: "left",
						data: [
					"类别一",
					"类别二",
					"类别三",
					"类别四",
					"类别五"
				]
			},
			series: [
				{
					name: "Traffic Sources",
					type: "pie",
					radius: "55%",
					center: ["50%", "60%"],
					data: [
						{ value: 335, name: "类别一" },
						{ value: 310, name: "类别二" },
						{ value: 234, name: "类别三" },
						{ value: 135, name: "类别四" },
						{ value: 1548, name: "类别五" }
					],
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: "rgba(0, 0, 0, 0.5)"
						}
					}
				}
			]
		}

		return {
			option
		}
	}
}
</script>